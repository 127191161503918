
























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { formatPercent } from '@/utils';
import { PokerSession, PokerRound, PokerParticipant, PokerParticipants, PokerTheme, User } from '../models';
import Card from './Card.vue';

@Component({ components: { Card } })
export default class Estimate extends Vue {
  @Prop({ type: Object as () => PokerSession, required: true })
  readonly session!: PokerSession;

  @Prop({ type: Object as () => PokerTheme, required: true })
  readonly theme!: PokerTheme;

  formatPercent = formatPercent;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }
  get username(): string {
    return this.$store.getters['user/username'];
  }
  get isOwner(): boolean {
    return this.session.owner === this.userId;
  }
  get round(): PokerRound | null {
    return this.session.round;
  }
  get estimating(): boolean {
    return this.round !== null;
  }
  get participants(): PokerParticipant[] {
    return this.session && this.session.people ? Object.values(this.session.people) : [];
  }
  get estimators(): PokerParticipants {
    const peopleArray = Object.entries(this.session.people);
    const estimatorsArray = peopleArray.filter(([id]) => id !== this.session.owner || this.session.ownerCanEstimate);
    return Object.fromEntries(estimatorsArray.sort(([, a], [, b]) => a.username!.localeCompare(b.username!)));
  }
  get totalMembers(): number {
    return Object.keys(this.estimators).length;
  }
  get totalEstimates(): number {
    return this.round ? Object.keys(this.round.estimates).length : 0;
  }
  get canRevealCards(): boolean {
    return this.totalEstimates > 0 && this.totalMembers === this.totalEstimates;
  }
  get roundNumber(): number {
    return this.session.rounds ? this.session.rounds.length + 1 : 1;
  }

  @Watch('session.round.estimates')
  onEstimateChange() {
    this.calculateRoundEstimate();
  }

  created() {
    this.$bus.$on('card-select', this.cardSelected);
  }

  isOwnCard(uid: string): boolean {
    return uid === this.userId;
  }
  getEstimate(uid: string): string | null {
    if (!this.round) return null;
    if (this.isOwnCard(uid)) return this.round.estimates[uid] || null;
    else if (this.canRevealCards) {
      return this.round.estimates[uid];
    } else return null;
  }
  getCardColor(uid: string): string {
    return this.round!.estimates![uid] ? 'secondary' : 'accent';
  }
  getUserColor(uid: string): string {
    let color = 'default';
    if (this.session.owner === uid) color = 'warning';
    else if (this.userId === uid) color = 'primary';
    return color;
  }

  startEstimation() {
    if (!this.isOwner) return;
    this.$bus.$emit('estimation-start', this.estimators);
  }
  restartEstimation() {
    if (!this.round || !this.isOwner) return;
    this.$bus.$emit('estimation-restart', this.round, this.estimators);
  }
  finishEstimation() {
    if (!this.round || !this.isOwner) return;
    this.$bus.$emit('estimation-finish', this.round, this.estimators);
    //if (this.session.autoStartRound) this.startEstimation();
  }

  removeEstimate(uid: string): void {
    if (!this.round || !this.round.estimates) return;
    if (this.isOwnCard(uid)) {
      const estimate = this.round.estimates[uid];
      this.$bus.$emit('estimation-remove', this.round, estimate, this.user);
    }
  }

  removePerson(person: PokerParticipant) {
    this.$bus.$emit('person-remove', this.round, person);
  }

  // Event listeners
  cardSelected(value) {
    if (!this.round) return;
    this.$bus.$emit('estimation-change', this.round, value, this.user);
  }

  // Helpers
  calculateRoundEstimate() {
    if (!this.round) return;
    const estimatesMap = Object.create({});
    for (const uid in this.round.estimates) {
      const estimate: string = this.round.estimates[uid];
      if (!estimatesMap[estimate]) estimatesMap[estimate] = 0;
      estimatesMap[estimate]++;
    }
    const deck = this.session.cards.value;
    const sortedEstimates = Object.keys(estimatesMap)
      .sort((a, b) => {
        const diff = estimatesMap[b] - estimatesMap[a];
        return diff === 0 ? deck.indexOf(b) - deck.indexOf(a) : diff;
      })
      .map((k) => {
        return { estimate: k, votes: estimatesMap[k] };
      });
    const points = sortedEstimates.length ? sortedEstimates[0].estimate : null;
    const totalVotes = Object.keys(this.round.estimates).length;
    const confidence = sortedEstimates.length ? sortedEstimates[0].votes / totalVotes : 0;
    //console.log('Estimate', sortedEstimates, points, totalVotes, confidence);
    this.$set(this.round, 'points', points);
    this.$set(this.round, 'confidence', confidence);
    //console.log('Calculate estimates', this.round.points, this.round.confidence);
    // Automatically complete round if high confidence
    if (this.isOwner && this.canRevealCards) {
      const autoCompleteLevel = (this.session['autoCompleteLevel'] || 0) / 100;
      if (autoCompleteLevel > 0 && confidence >= autoCompleteLevel) {
        this.finishEstimation();
      }
    }
  }
}
